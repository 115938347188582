import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

export default class TagTemplate extends React.Component {
  render() {
    const { pageContext } = this.props;
    const { tag } = pageContext;
    return (
      <Layout>
        <SEO title={`Posts tagged "${tag}"`}/>
        <section>
          <h1>{`Posts tagged "${tag}"`}</h1>

        </section>
      </Layout>
    )
  }
}

export const tagQuery = graphql`
  query($tag: String!) {
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            path
            tags
            title
            tags
            date
          }
        }
      }
    }
  }
`
